@import '../../styles/variables';

.listItem {
  box-sizing: border-box;
  padding: 17px 15px;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover,
  &:focus {
    outline: none;
  }

  &.dashboard {
    border-color: $dashboard-border;

    &:hover,
    &:focus {
      background-color: rgba($default-card-bg, 0.15);
    }
  }

  &.brew {
    border-color: rgba($stat-border, 0.5);

    &:last-child {
      border: none;
    }

    &:hover {
      background-color: rgba($brew-list-hover, 0.4);
    }
  }
}