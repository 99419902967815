@import '../../../styles/variables';
@import '../../../styles/utilities';

.editResultsMenu {
  margin-top: 8px;
  padding-top: 9px;
  text-align: left;
  line-height: 0;
}

.moreButton {
  padding: 0;
  margin: 0;
  height: auto;
}

.divider {
  margin: 8px 0;
  border: none;
  border-bottom: 1px solid $tooltip-border;
}

strong {
  position: relative;
}

input.editInput {
  display: inline-block;
  margin: 0;
  padding: 0;
  background: transparent;
  min-width: 0;
  width: 35px;
  height: auto;
  font-size: px-to-rem(12px);

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &Center {
    text-align: center;
  }

  &Medium {
    height: 20px;
    width: 100%;
    font-size: px-to-rem(13px);
    font-weight: $weight-light;

    @include for-tablet-portrait-up () {
      font-size: px-to-rem(17px);
      font-weight: $weight-regular;
    }
  }

  &Large {
    width: 100%;
    font-size: px-to-rem(14px);
    font-weight: $weight-light;

    @include for-tablet-portrait-up () {
      font-size: px-to-rem(15px);
    }

    @include for-tablet-landscape-up () {
      font-size: px-to-rem(17px);
    }

    @media (min-width: 1075px) {
      font-size: px-to-rem(24px);
    }
  }
}

.originalValue {
  display: block;
  font-size: px-to-rem(10px);
  color: $placeholder-dark;
  line-height: 1;
}

.bottomSpacing {
  margin-bottom: 4px;
}

.ingredientListItem {
  padding: 0 15px;
  min-height: 42px;
  align-items: center;
}

.editable:not(.editing) {
  &::before {
    content: '';
    position: absolute;
    display: block;
    left: -5px;
    width: 90%;
    height: 100%;
    background: rgba($button-brown-bg, 0);
    border-radius: 4px;
    transition: background 0.3s;
  }

  &:hover::before {
    background: rgba($button-brown-bg, 0.25);
  }
}

input.amountEditor {
  width: calc(100% - 35px);
  min-width: calc(100% - 35px);
  padding: 5px 7px;
  margin: 0;
  height: auto;
  background-color: $feedback-modal-input-bg;
  font-size: 14px;
  transform: translateX(-7px);
}