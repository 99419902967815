@import '../../styles/variables/';
@import '../../styles/utilities';


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: $header-height;
  background-color: $header-bg;

  .logoLink {
    line-height: 0;
  }

  .loginLink {
    color: $link;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: $weight-medium;
    transition: color 0.5s;
    font-size: px-to-rem(12px);

    &:hover,
    &:focus {
      color: $link-hover;
    }
  }

  &__user {
    position: relative;
    width: 35px;
    height: 35px;
  }

  .avatar {
    cursor: pointer;
  }

  .userMenu {
    text-align: right;
    padding: 20px;

    &__header {
      text-transform: none;
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;

      button {
        white-space: nowrap;

        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
}