@import '../../styles/variables';

.snackbar {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 20px 17px;
  max-width: 350px;
  min-width: 275px;
  box-sizing: border-box;
  background: $snackbar-bg;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
  color: $snackbar-text;
  text-align: center;
  font-weight: $weight-medium;
  z-index: 10;
  animation-name: fadeIn;
  animation-duration: 0.5s;

  &.close {
    animation-name: fadeOut;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
}

.success {
  color: $success-color;
}

.error {
  color: $error-color;
}

.warning {
  color: $warning-color;
}

@keyframes fadeIn {
  from {opacity: 0; transform: translate(-50%, 20px);}
  to {opacity: 1; transform: translate(-50%, 0px);}
}

@keyframes fadeOut {
  from {opacity: 1; transform: translate(-50%, 0px);}
  to {opacity: 0; transform: translate(-50%, 20px);}
}