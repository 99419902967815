@import './variables';
@import './utilities';

label {
  position: relative;
  font-size: px-to-rem(11px);
  transition: color 0.3s;

  a {
    color: $error-color;
    transition: color 0.3s;

    &:hover {
      color: $white;
    }
  }
}

.error {
  color: $error-color;
}

.success {
  color: $success-color;
  font-weight: 500;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="date"],
select,
textarea {
  @extend %inputStyles;

  &.error {
    border: 1px solid $error-color;
  }

  &.dark {
    background-color: rgba($input-bg-dark, 0.3);
    color: $text-light;

    &::placeholder {
      color: $placeholder-light;
      font-weight: $weight-light;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' class='svg-triangle' width='10' height='10'><path d='M 5,6 10,0 0,0 z' fill='rgb(46, 25, 34)' /></svg>");
  background-position: calc(100% - 15px) 19px;
  background-repeat: no-repeat;
}

select::-ms-expand {
  display: none; /* hide the default arrow in ie10 and ie11 */
}

textarea {
  padding: 15px;
  max-width: 100%;
  min-width: 100%;
  min-height: 100px;
  max-height: 500px;
  height: auto;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] {
  +label {
    display: flex;
    align-items: center;
    padding: 8px 0 11px;
    height: 22px;
    cursor: pointer;

    &::before {
      content: '';
      position: relative;
      display: block;
      margin-right: 5px;
      width: 22px;
      height: 22px;
      border-radius: 2px;
      background-color: rgba($button-brown-bg, 0.3);
    }
  }

  &:checked {
    +label::before {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24'><path d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z' fill='rgb(46, 25, 34)'/></svg>");
      background-position: 3px 4px;
      background-repeat: no-repeat;
    }
  }
}

// toggle mode
input[type="checkbox"].toggle {
  +label {
    &::before {
      display: none;
    }
  
    .toggle {
      display: inline-block;
      position: relative;
      margin: 0 5px;
      width: 50px;
      height: 30px;
      border: none;
      border-radius: 3px;
      background-color: $button-default-bg;
      outline: none;
      vertical-align: middle;
      font-size: 1rem;
      cursor: pointer;

      &::before {
        content: '';
        display: block;
        position: absolute;
        margin-top: 4px;
        left: 4px;
        width: 22px;
        height: 22px;
        border-radius: 2px;
        background-color: rgba($default-card-bg, 0.45);
        transition: left 0.3s;
      }

      &.dark {
        background-color: $black;
      }
    }
  }

  &:checked {
    +label .toggle::before {
      left: 24px;
    }
  }
}

input[type="radio"] {
  display: none;
}

input[type="radio"] {
  +label {
    display: flex;
    align-items: center;
    padding: 2px 0 4px;
    height: 22px;
    cursor: pointer;

    &::before {
      content: '';
      position: relative;
      display: block;
      margin-right: 5px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: rgba($button-brown-bg, 0.3);
      transition: background-color 0.3s;
    }
  }

  &:checked {
    +label::after {
      content: '';
      position: absolute;
      left: 6px;
      width: 8px;
      height: 8px;
      background-color: $text-light;
      border-radius: 50%;
    }
  }

  &:hover,
  &:focus {
    +label::before {
      background-color: rgba($default-card-bg, 0.4);
    }
  }
}

input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

input[type="file"] + label {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 0 10px;
  padding: 0 15px;
  width: 100%;
  min-width: 130px;
  height: 42px;
  border: none;
  border-radius: 4px;
  outline: none;
  font-size: px-to-rem(14px);
  font-family: $montserrat;
  line-height: 2.6;
  overflow: hidden;

  &.dark {
    background-color: rgba($input-bg-dark, 0.3);
    color: $placeholder-light;
    font-weight: $weight-light;
  }

  &.filled {
    color: $text-light;
  }
}