@import '../../../styles/variables';
@import '../../../styles/utilities';

.Activity {
  position: relative;
  margin: 3px;
  width: 12px;
  height: 12px;
  background-color: rgba($default-card-bg, 0.25);

  @include for-phone-only {
    width: 7px;
    height: 7px;
    margin: 1px 1px 2px;
  }

  &.active {
    background-color: $canary;
  }

  span {
    display: block;
    white-space: nowrap;
  }

  .tooltip {
    font-size: px-to-rem(10px);
    line-height: 1.2;
  }
}