@import '../../styles/variables';
@import '../../styles/utilities';


.ActivityPanel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 15px 15px;

  &__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 5px;
    width: 144px;
    height: 234px;

    @include for-phone-only {
      width: 260px;
      height: 41px;
      margin-bottom: 20px;
    }

    @include for-tablet-landscape-up {
      width: 234px;
      height: 144px;
    }

    @include for-desktop-up {
      width: 468px;
      height: 72px;
    }
  }

  .year {
    padding-bottom: 1px;
    color: $label-color;
    font-size: px-to-rem(10px);

    @include for-phone-only {
      position: absolute;
      font-size: px-to-rem(9px);

      &:first-child {
        left: 10%;
      }

      &:last-child {
        right: 10%;
      }
    }
  }
}