@import '../../styles/variables/';
@import '../../styles/utilities';

.footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  height: $footer-height-mobile;
  background-color: $footer-bg;
  color: rgba($text-light, 0.6);
  font-size: px-to-rem(9px);
  z-index: 5;
  box-sizing: border-box;
  line-height: 2;

  @include for-tablet-portrait-up {
    padding: 0 20px;
    height: $footer-height;
    color: $text-light;
  }

  @include for-tablet-landscape-up {
    font-size: px-to-rem(10px);
  }

  p {
    margin: 0;
  }

  p:last-of-type {
    text-align: right;

    @include for-tablet-portrait-up {
      text-align: left;
    }
  }

  span {
    display: none;

    @include for-tablet-portrait-up {
      display: inline;
    }
  }

  br {
    display: inline;

    @include for-tablet-portrait-up {
      display: none;
    }
  }

  .link {
    text-transform: none;
    color: rgba($text-light, 0.6);
    font-weight: $weight-regular;
    font-size: px-to-rem(9.5px);
    text-decoration: none;
    line-height: 2;

    @include for-tablet-portrait-up {
      line-height: 1.2;
      color: $text-light;
    }
  }
}

.modalClass {
  > div {
    max-width: 88%;
    max-height: 94vh;
    overflow-y: scroll;

    @include for-tablet-portrait-up {
      max-width: 80%;
      max-height: 85vh;
    }
    
    > div {
      margin-top: 20px;
      justify-content: flex-start !important;
    }
  }
}

.sm {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: 0 5px;
  background-image: url("../../resources/images/sm.svg");
  background-repeat: no-repeat;
  background-size: auto 13px;
  background-position: 1px 1px;
  overflow: hidden;
  transform: translateY(2px);

  span {
    visibility: hidden;
    font-size: 0;
  }
}

.twitter {
  background-position: -25px 1px;
}

.facebook {
  background-position: -48px 1px;
  margin: 0 15px 0 0;
}