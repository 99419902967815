@import './styles/variables';
@import './styles/type';
@import './styles/buttons';
@import './styles/forms';

body {
  margin: 0;
  padding: 0;
  background-color: $site-bg;
  font-family: $montserrat;
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div,
section {
  box-sizing: border-box;
}

strong {
  font-weight: $weight-semibold;
}

.capitalize {
  text-transform: capitalize;
}

.light {
  color: $text-light;
}

.divider {
  display: flex;
  align-items: center;
  margin: 30px 0 10px;
  text-transform: uppercase;

  span {
    display: block;
    margin-right: 10px;
    white-space: nowrap;
  }

  &::after {
    content: '';
    display: block;
    margin-top: 1px;
    width: 100%;
    border-bottom: 1px solid rgba($label-color, 0.5);
  }
}

canvas {
  display: none;
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  z-index: 2;
  width: 100%;
  pointer-events: none;
}

.tag {
  position: relative;
  display: inline-block;
  height: 25px;
  padding: 3px 10px 0;
  border-radius: 25px;
  background-color: rgba($button-brown-bg, 0.5);
  font-weight: $weight-semibold;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    .removeTag {
      right: 0;
    }
  }

  .removeTag {
    position: absolute;
    right: -50px;
    top: 0;
    width: 25px;
    height: 25px;
    font-size: 0;
    border-radius: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: $brew-card-bg;
    transition: right 0.3s;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 7px;
      left: 11px;
      display: block;
      width: 2px;
      height: 12px;
      background-color: $text-dark;
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }
}