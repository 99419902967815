@import '../../styles/variables';
@import '../../styles/utilities';

$searchAndFiltersHeight: 41px;

.selectContainer {
  @extend %inputStyles;
  position: relative;
  display: flex;
  align-items: center;
  min-width: 50px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' class='svg-triangle' width='10' height='10'><path d='M 5,6 10,0 0,0 z' fill='rgb(46, 25, 34)' /></svg>");
  background-position: calc(100% - 15px) 19px;
  background-repeat: no-repeat;
  font-size: px-to-rem(13px);
  cursor: pointer;

  &[class~="lightInput"] {
    background-color: $brew-card-bg;
    color: $text-dark;

    &::placeholder {
      color: $placeholder-dark;
      font-weight: $weight-light;
    }
  }

  &[class~="darkInput"] {
    background-color: rgba($default-card-bg, 0.3);
    color: $text-light;

    &::placeholder {
      color: $text-dark;
      font-weight: $weight-light;
    }
  }
}

.searchAndFilters {
  $color: rgba($label-color, 0.99);

  display: flex;
  align-items: center;
  padding: 0 10px;
  height: $searchAndFiltersHeight;
  border-bottom: $select-option-bg-selected 1px solid;
  background-image: url("data:image/svg+xml;utf8,<svg width='18' height='18' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='8' cy='8' r='7.25' stroke='#{$color}' stroke-width='1.5'/><path d='M13.0605 14L17.9999 18.9393' stroke='#{$color}' stroke-width='1.5' stroke-linecap='round'/></svg>");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 12px;

  &.isSearching {
    background-image: none;
  }

  .placeholder {
    color: $label-color;
    text-transform: none;
    &.isSearching {
      color: $text-light;
    }
  }

  .closeButton {
    position: absolute;
    right: 10px;
    width: 18px;
    height: 18px;
    border: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.77002 2L15.14 16.44' stroke='#{$color}' stroke-width='1.5' stroke-linecap='round'/><path d='M15.1421 2.06065L1.76772 16.435' stroke='#{$color}' stroke-width='1.5' stroke-linecap='round'/></svg>");
    cursor: pointer;
    
    &:focus {
      outline: none;
    }
  }

  input[type="text"].selectSearch {
    background-color: transparent;
    padding: 0;
    margin: 0;
    color: $text-light;

    &::placeholder {
      color: $label-color;
    }
  }
}

.selectDropdown {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-height: 415px;
  background-color: $select-bg;
  color: $text-light;
  z-index: 9;

  &.open {
   display: block;
  }
}

.selectOptions {
  display: flex;
  padding: 5px 0;
  flex-direction: column;
  width: 100%;
  max-height: 374px;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba($button-shadow, 0.7);
}

.option {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0 8px;
  height: 28px;
  font-size: px-to-rem(12px);
  line-height: 0.9;
  outline: none;
  border-left: transparent 2px solid;
  transition: background-color 0.3s;

  &.selected {
    border-left: $selected-option-border 2px solid;
  }

  &.focused,
  &:hover,
  &:focus {
    background-color: $select-option-bg-selected;
  }

  @include for-phone-only {
    height: 40px;
    font-size: px-to-rem(13px);
  }

  > div {
    pointer-events: none;
  }
}