@import '../../styles/variables';

.tooltip {
  position: absolute;
  display: block;
  top: 0;
  background-color: rgba($site-bg, 0.9);
  padding: 10px 15px;
  border-radius: 5px;
  line-height: 1;
  color: $text-light;
  transition: opacity 0.3s;
  transition-delay: 0s;
  z-index: 10;
  outline: none;
  animation-name: fadeIn;
  animation-duration: 0.3s;
  
  &.close {
    animation-name: fadeOut;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
  }

  &.right {
    top: 50%;
    left: calc(100% + 8px);
    transform: translateY(-50%);

    &::after {
      left: -4px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-right: 4px solid rgba($site-bg, 0.9);
    }
  }

  &.bottom,
  &.bottom-center {
    top: calc(100% + 11px);
    left: 50%;
    transform: translateX(-50%);

    &::after {
      top: -6px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid rgba($site-bg, 0.9);
    }
  }

  &.bottom-right {
    @extend .bottom;
    transform: translateX(calc(-100% + 18px));

    &::after {
      left: auto;
      right: 7px;
    }
  }

  &.top,
  &.top-center {
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - 8px));

    &::after {
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid rgba($site-bg, 0.9);
    }
  }

  &.top-right {
    @extend .top;
    transform: translate(calc(-100% + 18px), calc(-100% - 8px));

    &::after {
      left: auto;
      right: 7px;
    }
  }

  &.top-left {
    @extend .top;
    transform: translate(calc(0% - 17px), calc(-100% - 8px));

    &::after {
      right: auto;
      left: 17px;
    }
  }

  &.left-center {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);

    &::after {
      right: -6px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid rgba($site-bg, 0.9);
    }
  }
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}