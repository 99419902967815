@import '../../styles/variables';
@import '../../styles/utilities';

.modalOverlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $modal-overlay;
  cursor: pointer;
  animation-name: fadeIn;
  animation-duration: 0.3s;
  z-index: 10;

  &.close {
    animation-name: fadeOut;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;

    .modal {
      animation-name: flowDown;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
    }
  }
}

.modal {
  position: relative;
  box-sizing: border-box;
  padding: 25px;
  margin: 50vh 0 0 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%;
  background-color: $modal-bg;
  cursor: default;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
  animation-name: flowUp;
  animation-duration: 0.3s;

  @include for-tablet-portrait-up {
    padding: 35px;
  }

  header {
    margin-bottom: 20px;
    font-weight: $weight-light;
    font-size: px-to-rem(18px);
  }
}

.modalButtons {
  display: flex;
  justify-content: center;

  button:not(:last-child) {
    margin-right: 10px;
  }
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes flowUp {
  from {margin-top: calc(50vh + 20px);}
  to {margin-top: 50vh;};
}

@keyframes flowDown {
  from {margin-top: 50vh;}
  to {margin-top: calc(50vh + 20px);};
}