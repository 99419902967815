@import '../../styles/variables';

.loginSignup {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - #{$header-height + $footer-height + 100px});

  &__header {
    margin-bottom: 20px;
    color: $text-light;
    text-align: center;
  }

  &__label {
    color: $label-color;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 23px;

    input {
      margin-right: 10px;
    }
  }

  .container {
    position: relative;
    perspective: 400px;
    width: 300px;
    height: 475px;
  }

  .signUpPanel,
  .logInPanel,
  .forgotPanel {
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px;
    transform-style: preserve-3d;
    background-image: linear-gradient(125.4deg, transparent 30%, rgba($coral, 0.5) 50%, transparent 70%);
    background-size: 275%;
    background-repeat: no-repeat;
    background-position-x: 300px;
  }

  .logInPanel {
    top: 40px;
  }

  .signUpPanel {
    top: -20px;
    opacity: 0;
    transform: rotateY(-180deg);
    pointer-events: none;
  }

  .forgotPanel {
    top: 20px;
    opacity: 0;
    transform: rotateY(-180deg);
    pointer-events: none;

    p {
      color: $label-color;
    }
  }

  .front {
    .logInPanel {
      animation: flipFront 1.4s, flashFront 1.4s;
      animation-fill-mode: forwards;
      display: block;
      pointer-events: auto;
    }

    .signUpPanel {
      animation: flipBack 1.4s, flashBack 1.4s;
      animation-fill-mode: forwards;
      pointer-events: none;
    }
  }

  .back {
    .signUpPanel {
      animation: flipFront 1.4s, flashFront 1.4s;
      animation-fill-mode: forwards;
      display: block;
      pointer-events: auto;
    }

    .logInPanel {
      animation: flipBack 1.4s, flashBack 1.4s;
      animation-fill-mode: forwards;
      pointer-events: none;
    }
  }

  .forgotFront {
    .logInPanel {
      animation: flipFront 1.4s, flashFront 1.4s;
      animation-fill-mode: forwards;
      display: block;
      pointer-events: auto;
    }

    .forgotPanel {
      animation: flipBack 1.4s, flashBack 1.4s;
      animation-fill-mode: forwards;
      pointer-events: none;
    }
  }

  .forgotBack {
    .forgotPanel {
      animation: flipFront 1.4s, flashFront 1.4s;
      animation-fill-mode: forwards;
      display: block;
      pointer-events: auto;
    }

    .logInPanel {
      animation: flipBack 1.4s, flashBack 1.4s;
      animation-fill-mode: forwards;
      pointer-events: none;
    }
  }
}

.loginSignupButton {
  display: flex;
  align-items: center;
  padding: 0 15px;
  line-height: 0;

  svg {
    margin: -3px -5px 0 0;
  }
}

.forgotButton {
  display: block;
  margin: 20px auto 0;
  color: $button-default-text;
  text-transform: capitalize;
  font-weight: $weight-regular;

  &:hover {
    color: $white;
  }
}

.googleButton {
  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: none;

    svg {
      margin-right: 5px;
    }
  }
}

@keyframes flipFront {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  29% {
    opacity: 0;
  }
  29.5% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@keyframes flipBack {
  0% {
    transform: rotateY(0deg);
    opacity: 1;
  }
  29% {
    opacity: 1;
  }
  29.5% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: rotateY(-180deg);
  }
}

@keyframes flashBack {
  0% {
    background-position-x: 300px;
  }
  100% {
    background-position-x: -1800px;
  }
}

@keyframes flashFront {
  0% {
    background-position-x: -2200px;
  }
  20% {
    background-position-x: -2200px;
  }
  100% {
    background-position-x: 500px;
  }
}