@import '../../styles/variables';
@import '../../styles/utilities';

.fade {
  overflow: hidden;

  main {
    margin: 0 auto;
    padding: 30px 0;
    width: calc(100% - 40px);
    max-width: 1400px;
    min-height: calc(100vh - #{$header-height + $footer-height-mobile});
    box-sizing: border-box;

    @include for-tablet-portrait-up {
      padding: 50px 0;
      min-height: calc(100vh - #{$header-height + $footer-height});
    }
  }

  &.default main {
    position: relative;
    margin: 0;
    padding: 0;
    min-height: calc(100vh - #{$footer-height-mobile});
    max-width: 100vw;
    width: 100%;

    @include for-tablet-portrait-up {
      min-height: calc(100vh - #{$footer-height});
    }
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &::before {
    z-index: -2;
  }

  &::after {
    z-index: -1;
    opacity: 0;
    transition: opacity 1s cubic-bezier(.23,0,.21,.99);
  }

  &.transitioned::after {
    opacity: 1;
    transition: none;
  }

  &.default::before,
  &.old-default::after,
  &.dashboard::before,
  &.old-dashboard::after,
  &.brews::before,
  &.old-brews::after,
  &.calculators::before,
  &.old-calculators::after,
  &.user::before,
  &.old-user::after {
    background-image: linear-gradient(125.4deg, $default-bg-1 0%, $default-bg-2 118.45%);
  }

  &.login::before,
  &.old-login::after,
  &.profile::before,
  &.old-profile::after {
    background-image: linear-gradient(125.4deg, darken($default-bg-1, 8%) 0%, $default-bg-2 85%);
  }

  &.brew::before,
  &.old-brew::after,
  &.new-brew::before,
  &.old-new-brew::after {
    background-image: linear-gradient(113.55deg, rgba($site-bg, 0) 28.14%, rgba($site-bg, 0.101351) 47.12%, rgba($site-bg, 0.278716) 62.17%, $site-bg 96.85%), linear-gradient(113.55deg, $brew-bg-1 0%, $brew-bg-2 79.84%);
  }
}