@import '../../../styles/variables';
@import '../../../styles/utilities';

.user {
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-rows: auto;
  grid-template-areas: 'avatar info';
  grid-gap: 10px;
  align-items: center;

  @include for-tablet-portrait-up () {
    grid-template-columns: 80px auto;
    grid-gap: 20px;
  }

  .avatarContainer {
    grid-area: avatar;
    height: 50px;

    @include for-tablet-portrait-up () {
      height: 80px;
    }
  }

  .infoContainer {
    grid-area: info;

    &__header {
      color: $text-light;
      margin-bottom: 5px;
    }

    &__stats {
      margin: 0;
      color: $label-color;
      font-size: px-to-rem(11px);

      span {
        margin-right: 10px;
      }
    }
  }
}