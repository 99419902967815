@import './variables';
@import './utilities';

h1,
.h1 {
  margin: 0;
  font-weight: $weight-light;
  font-size: px-to-rem(25px);
  text-transform: none;
  letter-spacing: 0;
  line-height: 1;

  @include for-tablet-landscape-up () {
    font-size: px-to-rem(28px);
  }
}

h2,
.h2 {
  margin: 0;
  text-transform: uppercase;
  font-weight: $weight-medium;
  font-size: px-to-rem(15px);
  letter-spacing: 1px;
}