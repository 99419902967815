@import '../../styles/variables';
@import '../../styles/utilities';

.calculators {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;

  .topRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @include for-tablet-portrait-up () {
      flex-direction: row;
    }

    .controls {
      display: flex;
      min-width: 40%;
    }

    label[for="units"] {
      margin-top: 6px;
      color: $label-color;
    }

    input.search {
      width: 100%;
      min-width: 150px;
      margin-left: 25px;
      padding: 0 50px 0 15px;
      background-color: rgba($default-card-bg, 0.15);
      color: $search-text;
      background-image: url('../../resources/images/search.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 15px) 12px;

      &::placeholder {
        color: rgba($error-color, 0.5);
      }
    }
  }

  &__header {
    color: $text-light;
    margin-bottom: 5px;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .noResults {
    margin-top: 50px;
    padding: 11px 30px;
    color: $text-light;
    border: 1px solid $text-dark;
  }
}

.card {
  display: inline-block;
  padding: 20px;
  margin: 15px 0;
  width: 100%;
  background-color: rgba(233,102,44,0.15);
  box-sizing: border-box;
  color: $white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);

  @include for-tablet-portrait-up () {
    margin: 15px 15px;
    width: 308px;
  }

  h2 {
    margin-bottom: 20px;
    text-transform: none;
    font-weight: $weight-light;
    font-size: px-to-rem(18px);
    letter-spacing: 0;
  }

  h3 {
    font-weight: $weight-regular;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: px-to-rem(10px);
  }

  label {
    color: $label-color;
  }

  p {
    font-size: px-to-rem(16px);

    span {
      font-size: px-to-rem(10px);
    }
  }

  input[type="text"],
  input[type="number"],
  input[type="date"],
  select,
  textarea {
    background-color: rgba($default-card-bg, 0.3);
    font-size: px-to-rem(13px);
    color: $text-light;

    &::placeholder {
      color: $text-dark;
      font-weight: $weight-light;
    }
  }
}

.row,
.rowQuad,
.interiorRow,
.interiorRow2 {
  display: grid;
  grid-template-columns: 1fr 1fr 27px;
  grid-column-gap: 10px;
  padding-top: 3px;
  margin-bottom: 2px;
  align-items: end;
  border-bottom: 1px solid rgba($input-bg-dark, 0.3);

  &:last-of-type {
    border-bottom: none;
  }

  button {
    align-self: center;
    transform: translateY(5px);
  }

  label {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  input,
  select {
    min-width: 50px;
  }
}

.rowQuad {
  grid-template-columns: 1fr 27px;
}

.interiorRow,
.interiorRow2 {
  grid-template-columns: 1fr 1fr;
  border-bottom: none;
  margin-bottom: 0;
}

.interiorRow2 {
  grid-template-columns: 0.65fr 1fr;
}