@import '../../styles/variables';

.row,
.rowThirds {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  padding-top: 3px;

  input,
  select {
    min-width: 50px;
  }
}

.rowThirds {
  grid-template-columns: 1fr 1fr 1fr;
}

.rowOneThird {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 10px;
  padding-top: 3px;
}

.extra {
  margin: 3px 0 13px 0;
  line-height: 1.5;
}

.alignRight {
  float: right;
}

input.inline {
  display: inline;
  width: 59px;
  height: inherit;
  min-width: inherit;
  padding: 0 3px;
  margin: 0;
  font-weight: $weight-semibold;
}

.edit {
  svg {
    width: 15px;
    height: 15px;
    margin-left: 8px;
    fill: darken($brew-callout, 9%);
  }

  &:hover {
    svg {
      fill: $text-dark;
    }
  }
}

.lock,
.unlock {
  svg {
    width: 15px;
    height: 17px;
    margin-left: 8px;
    stroke: darken($brew-callout, 9%);
  }

  &:hover {
    svg {
      stroke: $text-dark;
    }
  }
}

.unlock {
  svg {
    width: 15px;
    height: 17px;
  }
}

.fullButton {
  margin: 20px 0 20px;
  width: 100%;
}

.unused {
  opacity: 0.5;
  transition: opacity 0.3s;

  &:focus {
    opacity: 1;
  }
}

sub {
  line-height: 0;
}

.tagWrapper {
  margin-bottom: 10px;

  div {
    margin-right: 5px;
  }
}

.info {
  font-size: px-to-rem(13px);
  line-height: 3.6;
}

.gridOption2Col,
.gridOption3Col {
  display: grid;
  width: 100%;

  span {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      justify-content: flex-end;
    }

    &.category {
      &::before {
        content: '';
        flex-shrink: 0;
        display: block;
        width: 10px;
        height: 10px;
        margin-right: 9px;
        border-radius: 5px;
      }

      @each $category in $categories {
        &[class~="#{$category}"] {
          &::before {
            background-color: map-get($category-colors, $category);
          }
        }
      }
    }
  }

  .yellow {
    color: $label-color;
  }
}

.gridOption2Col {
  grid-template-columns: auto 130px;
}

.gridOption3Col {
  grid-template-columns: auto 30px 80px;
}