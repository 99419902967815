@import '../../styles/variables';
@import '../../styles/utilities';
@import '../../styles/type';

.dashboard {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: 0;
  grid-template-areas:
    'topRow'
    'topRow'
    'leftColumn'
    'rightColumn';
  margin-bottom: 60px;

  @include for-tablet-portrait-up () {
    grid-template-areas:
      'topRow topRow'
      'leftColumn rightColumn';
    grid-template-columns: 0.5fr 0.5fr;
    margin-bottom: 0;
    grid-gap: 20px;
  }

  .topRow {
    grid-area: topRow;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;

    @include for-tablet-portrait-up () {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;
    }

    a {
      @include for-phone-only () {
        position: fixed;
        bottom: 80px;
        right: 10px;
        width: 90px;
        height: 90px;
        padding: 22px 0 0;
        border-radius: 50%;
        text-align: center;
        z-index: 10;
        box-shadow: 0 5px 15px 2px darken($text-dark, 3%);
        font-weight: $weight-regular;
      }
    }
  }

  .leftColumn {
    grid-area: leftColumn;
    width: 100%;
  }

  .rightColumn {
    grid-area: rightColumn;
  }

  .flex {
    display: flex;
  }

  &__label {
    margin: 0;
    padding: 15px 15px;
    font-size: px-to-rem(11px);
    font-weight: $weight-semibold;
    color: $error-color;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }

  &__header {
    padding: 0 15px;
    font-size: px-to-rem(16px);
    color: $dashboard-header;
    font-weight: $weight-medium;
    font-style: italic;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }

  .cardLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px 15px 0;
    width: 100%;
    min-height: 79px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    font-family: $montserrat;
    transition: background-color 0.3s;

    &:hover {
      background-color: rgba($default-card-bg, 0.15);
      cursor: pointer;
    }
  }

  .brewLog {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 116px;
    max-height: 530px;
    overflow: hidden;

    li {
      transition: all 0.3s;
    }
    

    &.page1 {
      li[data-custom=page1] {
        margin-left: 0;
      }
    }

    &.page2 {
      li[data-custom=page1] {
        margin-left: -100%;
      }
    }

    .noBrews {
      padding-left: 15px;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      color: $text-light;

      span {
        padding-top: 3px;
        color: $label-color;
        font-size: px-to-rem(10px);
        line-height: 0;
        letter-spacing: 0.03em;

        &.pill {
          display: inline-flex;
          height: 15px;
          align-content: center;
          padding: 1px 2px 0;
          margin-left: 8px;
          transform: translateY(-1px);
          border-radius: 3px;
          background-color: $label-color;
          line-height: 1.3;
          color: $text-dark;
        }
      }

      p {
        padding: 0;
        margin: 0;
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px 20px;

      @include for-tablet-portrait-up () {
        padding: 20px 15px;
      }

      a {
        color: $dashboard-link;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: $weight-medium;
        transition: color 0.3s;
    
        &:hover,
        &:focus {
          color: $dashboard-link-hover;
        }
      }
    }
  }
}

.brewLogLoader {
  transform: translateY(40px);
}

.activityLoader {
  display: flex;
  padding-bottom: 25px;
  transform: translateY(-5px);
}

.formWrapper {
  max-height: 480px;
  overflow: scroll;
}

.donate {
  header {
    margin-bottom: 5px !important; // overrides
    font-weight: $weight-medium !important; // overrides
  }

  p {
    margin-top: 0;
  }
}