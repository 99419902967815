@import '../../styles/variables';
@import '../../styles/utilities';

.profile {
  margin: 0 auto 50px;
  max-width: 600px;

  header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .profilePic {
      width: 80px;
      height: 80px;
      margin-bottom: 10px;
    }
  }

  h1 {
    margin-bottom: 20px;
    color: $text-light;
    text-align: center;

    > span {
      margin-top: 3px;
      display: block;
      font-size: px-to-rem(11px);
      color: $error-color;
    }
  }

  label {
    color: $label-color;
  }
}

.form {
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 23px;
  }
}

.updateButton {
  display: flex;
  align-items: center;
  padding: 0 15px;
  line-height: 0;

  &.saving {
    svg {
      margin-top: -3px;
    }
  }
}

.uploadButton {
  display: flex;
  align-items: center;
  padding: 0 15px;
  line-height: 0;

  svg {
    margin: -3px -5px 0 0;
  }
}