@import '../../styles/variables';
@import '../../styles/utilities';

.noMatch {
  margin-top: 5vh;
  text-align: center;
  color: $text-light;

  @include for-tablet-portrait-up () {
    margin-top: 25vh;
  }

  .header {
    margin-bottom: 8px;
    font-size: px-to-rem(20px);
  }

  a {
    margin: 30px 10px 0;
  }
}