@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,500i,600');

// COLORS
// grayscale
$black: #191919;
$dark-gray: #2b2b2b;
$white: #ffffff;

// color
$coal: #251f1d;
$tar: #2e2318;
$brown: #58382a;
$caramel: #ab5c00;
$coral: #e9662c;
$embers: #ff6b00;
$amber: #dd8c0b;
$honey: #CC8B1D;
$gold: #ebaf3c;
$toast: #E4A61E;
$canary: #f4d03f;
$forsythia: #ffdb4a;
$cream: #fff7da;
$seafoam: #16a05e;

// Color assignments
$site-bg: $black;
$header-bg: $dark-gray;
$footer-bg: $black;
$default-bg-1: $brown;
$default-bg-2: $black;
$brew-bg-1: $canary;
$brew-bg-2: $embers;

$text-light: $white;
$text-dark: $tar;
$label-color: $gold;
$error-color: $coral;
$success-color: $seafoam;
$warning-color: $canary;
$placeholder-dark: $caramel;
$placeholder-light: $coral;
$search-text: $forsythia;

$dashboard-header: $canary;
$dashboard-border: rgba($tar, 0.5);

$link: $coral;
$link-hover: $canary;
$dashboard-link: $canary;
$dashboard-link-hover: $gold;

$button-default-bg: $tar;
$button-default-text: $canary;
$button-yellow: $canary;
$button-light-brown: $toast;
$button-shadow: $brown;
$button-link-hover: $gold;
$button-brown-bg: $caramel;
$button-green-bg: $seafoam;
$button-red-bg: $coral;
$button-error-bg: darken($coral, 20%);
$input-bg-dark: $coral;

$default-card-bg: $coral;
$brew-card-bg: $forsythia;
$brew-card-bg-view: $seafoam;

$brew-list-hover: $amber;
$brew-callout: $caramel;
$stat-border: $caramel;
$stat-background: $forsythia;
$draft-brew-border: $caramel;

$modal-overlay: rgba($black, 0.75);
$modal-bg: $canary;
$feedback-modal-input-bg: lighten($forsythia, 8%);

$snackbar-bg: $black;
$snackbar-text: $gold;

$tooltip-border: $amber;

$not-current-brew: $amber;

$select-bg: $coal;
$selected-option-border: $coral;
$select-option-bg-selected: rgba($coral, 0.25);

$popup-bg: $coal;
$graph-crosshair: rgba($coral, 0.25);

$categories:
  'bittering',
  'finishing',
  'dualPurpose',
  'base',
  'caramelCrystal',
  'adjunctGrains',
  'kilnedToasted',
  'roasted',
  'sugars';
$category-colors: (
  'bittering': #3ae492,
  'finishing': #92f132,
  'dualPurpose': #16a05e,
  'base': #ffdb4a,
  'caramelCrystal': #d48d1c,
  'adjunctGrains': #ffefb3,
  'kilnedToasted': #cf6f00,
  'roasted': #894200,
  'sugars': #c4c4c4,
);

// FONTS
$montserrat: 'Montserrat', sans-serif;

$base-font-size: 14px;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semibold: 600;

// LAYOUT
$header-height: 55px;
$footer-height: 40px;
$footer-height-mobile: 70px;

// BREAKPOINTS
$phone-max: 599px;
$tablet-portrait: 600px;
$tablet-landscape: 900px;
$desktop: 1200px;
$big-desktop: 1800px;


%inputStyles {
  display: inline-block;
  box-sizing: border-box;
  margin: 5px 0 10px;
  padding: 0 15px;
  width: 100%;
  min-width: 230px;
  height: 42px;
  border: none;
  border-radius: 4px;
  outline: none;
  font-size: (14px / $base-font-size) * 1rem;
  font-family: $montserrat;
}