@import './variables';

// CONVERT PX TO REM
@function px-to-rem($px, $base:$base-font-size) {
  @return ($px / $base) * 1rem;
}

// MEDIA QUERIES
@mixin for-phone-only {
  @media (max-width: $phone-max) { @content; }
}

@mixin for-tablet-portrait-up {
  @media (min-width: $tablet-portrait) { @content; }
}

@mixin for-tablet-landscape-up {
  @media (min-width: $tablet-landscape) { @content; }
}

@mixin for-desktop-up {
  @media (min-width: $desktop) { @content; }
}

@mixin for-big-desktop-up {
  @media (min-width: $big-desktop) { @content; }
}